/* eslint-disable import/no-anonymous-default-export */

export default {
    Home: {
        list: "/home"
    },
    Login: {
        list: "/login"
    },
    authResponse: {
        successResponse: "/auth/success",
        failureResponse: "/auth/failed",
        profile: "/account_details"
    },
    auth: {
        authenticatedFailure: "Vous n'avez pas l'accès à accéder à cette application.",
    },
    WaitingRoom: {
        list: "/waitingroom"
    },
    Calendar: {
        list: "/calendar"
    },
    Products: {
        list: "/products",
        favorite: "Ce produit a été ajouté à la liste avec succès",
        inFavorite: "Ce produit a été retiré de vos favoris avec succès",

    },
    Ordinances: {
        add: "L'ordonnance a été ajoutée avec succès",
        duplicate: "L'ordonnance a été dupliquée avec succès",
        update: "L'ordonnance a été modifié avec succès",
    },
    Quotes: {
        add: "Le devis a été ajouté avec succès",
        duplicate: "Le devis a été dupliquée avec succès",
        update: "Le devis a été modifié avec succès",
    },
    biologicalPrescriptions: {
        add: "L'ordonnance biologique a été ajoutée avec succès",
        duplicate: "L'ordonnance biologique a été duplquée avec succès",
        update: "L'ordonnance biologique a été modifié avec succès",
        atLeastOne: 'Veuillez sélectionner une analyse au minimum'
    },
    prostheticPrescriptions: {
        add: "L'ordonnance de prothèses a été ajoutée avec succès",
        duplicate: "L'ordonnance de prothèses a été duplquée avec succès",
        update: "L'ordonnance de prothèses a été modifié avec succès",
    },
    radiologicalAssessments: {
        add: "Le bilan radiologique a été ajoutée avec succès",
        duplicate: "Le bilan radiologique a été duplqué avec succès",
        update: "Le bilan radiologique a été modifié avec succès",
        atLeastOne: 'Veuillez sélectionner une analyse au minimum'
    },
    prescriptionModels: {
        add: "Le modèle d'ordonnance a été ajouté avec succès",
        update: "Le modèle d'ordonnance a été modifié avec succès",
    },
    Documents: {
        add: " Le fichier a été ajouté avec succès",
        update: " Le fichier a été modifié avec succès",

    },
    Patients: {
        list: "/patients",
        details: "/patient-details",
        update: "Le patient a été modifié avec succès",
        add: "Le patient a été ajouté avec succès",
        delete: "Le patient a été supprimé avec succès",
        restore: "Le patient a été restorée avec succès",
        multiDelete: "les modifications ont été effectuées avec succès",
        multiRestore: "les modifications ont été effectuées avec succès",

    },
    Consultations: {
        list: "/consultations",
        add: "La consultation a été ajoutée avec succès",
        update: "La consultation a été modifiée avec succès",
        emptyData: "Aucune consultation enregistrée",
    },
    Invoices: {
        list: "/invoices",
        emptyData: "Aucune facture enregistrée",
        paymentAdd: "Le paiement a été ajouté avec succès"
    },
    Reports: {
        list: "/reports"
    },
    Settings: {
        list: "/settings"
    },
    common: {
        requiredField: "Ce champ est requis",
        phoneNumber: "Votre numéro de téléphone n'est pas valide",
        year: "L'année saisie est invalide",
        email: "Votre E-mail n'est pas valide",
        atLeastOne: 'Veuillez sélectionner un produit au minimum',
        atLeastOneTraitement: 'Veuillez sélectionner un traitement au minimum'

    },
    Allergie: {
        add: "L'allergie a été ajoutée avec succès",
        update: "L'allergie a été modifiée avec succès",
    },
    Colleague: {
        add: "Le confrère a été ajouté avec succès",
        update: "Le confrère a été modifiée avec succès",
    },
    ProductSugg: {
        add: "la suggestion de produit a été ajouté avec succès",
        update: "La suggestion de produit a été modifié avec succès",
    },
    Antecedent: {
        add: "L'antécédent a été ajouté avec succès",
        update: "L'antécédent a été modifié avec succès",
    },
    Measurement: {
        add: "Les mesures ont été ajoutées avec succès",
        update: "Les mesures ont été modifiées avec succès",
    },
    dosage: {
        add: "La posologies a été ajouté avec succès"
    },
    appointment: {
        add: "Le rendez-vous a été ajouté avec succès",
        update: "Le rendez-vous a été modifié avec succès"
    },
    dentalCart: {
        atLeastOne: "Veuillez sélectionner au moins une dent pour lui affecter un soin",
        atLeastOnePathology: "Veuillez sélectionner au moins une dent pour lui affecter une pathologie",
        addComment: "Le commentaire a été ajouté avec succès",
        addImages: "Les images ont été ajoutées avec succès",
        changedAreSaved: "Les modifications ont été enregistrées avec succès",
        cancelDentalCare: "Le soin a été annulé avec succès",
        attributesAreRequired: "les Attributes des images sont obligatoire",
        fullAllDoctors: "Merci de remplir tous les praticien",

    }
}