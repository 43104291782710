import {
    Collapse, DropdownItem, Nav, Navbar as NavbarComponent, NavbarBrand, NavItem, NavItemMore, NavItemMoreBtn, NavItemMoreSubMenu, NavLink, NavLogoutLink,
    NavProfileLink, NavSubMenu, NavSubMenuItem, NavSubMenuLink, NavUserProfile
} from '@sobrus-com/sobrus-design-system';
import React, { useState } from 'react';
import { connect } from "react-redux";
import { Link, NavLink as NavLinkComponent } from "react-router-dom";
import Acl from '../../Helpers/Acl';
import monOrdonnaance from '../../Img/monOrdonnaance.png';
import authService from '../../Services/auth.service';
import { images, imagesSize } from "../../Themes/Images";
import aclActions from "../../Values/aclActions.json";
import routesPath from '../../Values/routesPath';
import "./Navbar.scss";
import AppConfig from '../../Config/AppConfig';
import CancellationBanner from 'Components/CancellationBanner/CancellationBanner';

const Navbar = ({ invoicePramas, authenticatedUser, ...props }) => {
    const [isActivePrescription, setIsActivePrescription] = useState(false);
    const [isActiveBiologicalPrescription, setIsActiveBiologicalPrescription] = useState(false);
    const [isActiveRadiologicalAssessments, setIsActiveRadiologicalAssessments] = useState(false);
    const [isActiveOrders, setIsActiveOrders] = useState(false);

    const [isActiveInvoice, setIsActiveInvoice] = useState(false);
    const [isActiveQuote, setIsActiveQuote] = useState(false);


    const logOut = async () => {
        await authService.logout()
    }

    const checkvalidUntil = () => {
        let today = new Date()
        if (authenticatedUser.validUntil && today.getTime() > new Date(authenticatedUser.validUntil).getTime()) {
            let collection = document.getElementsByClassName('sobrus-med-container');
            for (let i = 0; i < collection.length; i++) {
                collection[i].style.paddingTop = "108px";
            }
            return true
        }
        return false
    }

    return (
        <div className="NavBar">
            {checkvalidUntil() && <CancellationBanner />}
            <NavbarComponent>
                <NavbarBrand tag={Link} to={routesPath?.Home?.list}>
                    {authenticatedUser?.subscriptionType === "premium" ? <img style={imagesSize?.logo} src={images?.logo}></img> : <img style={{ minWidth: '200px', height: 'auto', width: "102.57px" }} src={monOrdonnaance}></img>}
                </NavbarBrand>
                <Collapse>
                    <Nav>
                        <Acl actions={[aclActions?.Posts?.list]}>
                            <NavItem>
                                <NavLink exact tag={NavLinkComponent} to={routesPath?.Home?.list}>Tableau de bord</NavLink>
                            </NavItem>
                        </Acl>
                        <Acl actions={[aclActions?.WaitingRoom?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.WaitingRoom.list}>Salle d'attente</NavLink>
                            </NavItem>
                        </Acl>

                        <Acl actions={[aclActions?.Appointments?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={`${routesPath?.Calendar?.list}?view=calendar`}>Agenda</NavLink>
                            </NavItem>
                        </Acl>

                        <Acl actions={[aclActions?.Products?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Products?.list}>Produits</NavLink>
                            </NavItem>
                        </Acl>
                        <Acl actions={[aclActions?.Offers?.list]}>
                            <NavItem>
                                <NavLink style={isActiveOrders ? { backgroundColor: "rgba(0,177,216,.08)", color: "#00acd2" } : {}} >Marketplace</NavLink>
                                <NavSubMenu>
                                    <Acl actions={[aclActions?.Offers?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveOrders(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveOrders(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.Offers?.list}
                                            >Marketplace</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                    <Acl actions={[aclActions?.Orders?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveOrders(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveOrders(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.Orders?.list}
                                            >Commande</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                </NavSubMenu>
                            </NavItem>
                        </Acl>

                        <Acl actions={[aclActions?.Prescriptions?.list]}>
                            <NavItem>
                                <NavLink style={(isActivePrescription || isActiveBiologicalPrescription || isActiveRadiologicalAssessments) ? { backgroundColor: "rgba(0,177,216,.08)", color: "#00acd2" } : {}} >Ordonnances</NavLink>
                                <NavSubMenu>
                                    <Acl actions={[aclActions?.Prescriptions?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActivePrescription(false);
                                                        return false;
                                                    } else {
                                                        setIsActivePrescription(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.Ordinances?.list}
                                            >Ordonnances médicales</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                    <Acl actions={[aclActions?.BiologicalPrescriptions?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveBiologicalPrescription(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveBiologicalPrescription(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.biologicalPrescriptions?.list}
                                            >Ordonnances biologiques</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                    <Acl actions={[aclActions?.BiologicalPrescriptions?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveRadiologicalAssessments(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveRadiologicalAssessments(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.RadiologicalAssessments?.list}
                                            >Bilans radiologiques</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                    {AppConfig.isDentist() && <Acl actions={[aclActions?.ProstheticPrescriptions?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveRadiologicalAssessments(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveRadiologicalAssessments(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.prostheticPrescriptions?.list}
                                            >Ordonnances de prothèses</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>}
                                </NavSubMenu>
                            </NavItem>
                        </Acl>

                        <Acl actions={[aclActions?.Documents?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Documents?.list}>Documents</NavLink>
                            </NavItem>
                        </Acl>


                        <Acl actions={[aclActions?.Patients?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Patients?.list}>Patients</NavLink>
                            </NavItem>
                        </Acl>


                        {!AppConfig.isDentist() && <Acl actions={[aclActions?.Consultations?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Consultations?.list}>Consultations</NavLink>
                            </NavItem>
                        </Acl>}

                        <Acl actions={[aclActions?.Invoices?.list]}>
                            {(props.establishmentParamsForUsage && !AppConfig.isDentist()) && <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Invoices?.list}>{invoicePramas?.isPrintableAsInvoice ? "Factures" : "Notes d'honoraires"}</NavLink>
                            </NavItem>}
                            {(props.establishmentParamsForUsage && AppConfig.isDentist()) && <NavItem>
                                <NavLink style={(isActiveInvoice || isActiveQuote) ? { backgroundColor: "rgba(0,177,216,.08)", color: "#00acd2" } : {}} >Facturation</NavLink>
                                <NavSubMenu>
                                    <Acl actions={[aclActions?.Invoices?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveInvoice(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveInvoice(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.Invoices?.list}
                                            >{invoicePramas?.isPrintableAsInvoice ? "Factures" : "Notes d'honoraires"}</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                    <Acl actions={[aclActions?.Invoices?.list]}>
                                        <NavSubMenuItem>
                                            <NavSubMenuLink
                                                isActive={(match, location) => {
                                                    if (!match) {
                                                        setIsActiveQuote(false);
                                                        return false;
                                                    } else {
                                                        setIsActiveQuote(true);
                                                        return true;
                                                    }
                                                }}
                                                tag={NavLinkComponent}
                                                to={routesPath?.Quote?.list}
                                            >Devis</NavSubMenuLink>
                                        </NavSubMenuItem>
                                    </Acl>
                                </NavSubMenu>
                            </NavItem>}
                        </Acl>
                        <Acl actions={[aclActions?.Reports?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Spent?.list}>Dépenses</NavLink>
                            </NavItem>
                        </Acl>
                        <Acl actions={[aclActions?.expense?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Reports?.list}>Rapports</NavLink>
                            </NavItem>
                        </Acl>
                        <Acl actions={[aclActions?.colleagues?.list]}>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Colleagues?.list}>Confrères</NavLink>
                            </NavItem>
                        </Acl>
                        <Acl allowed>
                            <NavItem>
                                <NavLink tag={NavLinkComponent} to={routesPath?.Settings?.list}>Paramètres</NavLink>
                            </NavItem>
                        </Acl>
                        <NavItemMore>
                            <NavItemMoreBtn title="Plus" />
                            <NavItemMoreSubMenu />
                        </NavItemMore>
                    </Nav>
                    {/* user infos  */}
                    <NavUserProfile
                        fullName={`${authenticatedUser?.firstName ?? ""} ${authenticatedUser?.lastName ?? ""}`}
                        className="NavUserProfile-profile-dropdown"
                    >
                        <DropdownItem>
                            <NavProfileLink href={routesPath.Settings.list} />
                        </DropdownItem>
                        <DropdownItem className="last-dropdown-item">
                            <NavLogoutLink style={{ cursor: "pointer" }} onClick={() => logOut()} />
                        </DropdownItem>
                    </NavUserProfile>
                    {/* end user infos  */}
                </Collapse>
            </NavbarComponent>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        authenticatedUser: state.AUTH.authenticatedUser,
        loadingAuthenticatedUser: state.AUTH.loadingAuthenticatedUser,
        invoicePramas: state.INVOICE_PARAMS_FOR_USAGE.invoiceParamsForUsage,
        establishmentParamsForUsage: state.ESTABLISHMENT_PARAMS_FOR_USAGE.establishmentParamsForUsage
    })
}


export default connect(mapStateToProps)(Navbar);

