
const initialState = {
    authenticatedUser: null,
    loadingAuthenticatedUser: false,
};

function authReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_AUTHENTICATED_USER":
            nextState = {
                ...state,
                authenticatedUser: action.value,
            };
            return nextState || state;
        case "LOADING_AUTHENTICATION_USER":
            nextState = {
                ...state,
                loadingAuthenticatedUser: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setAuthenticatedUser = (value) => ({ type: "SET_AUTHENTICATED_USER", value })
const setLoadingAuthenticatedUser = (value) => ({ type: "LOADING_AUTHENTICATION_USER", value })

// export variables
export { setAuthenticatedUser, setLoadingAuthenticatedUser };
export default authReducer;