import Api from "../Services/Api";
import dashboardService from "../Services/dashboard.service";
import postsService from "../Services/posts.service";
import productsService from "../Services/products.service";


class homeController {
    preloadData = (postsParams) => Api.all([dashboardService.getAll(),]);
    getAllPosts = (params) => postsService.getAll(params);
}
export default new homeController();