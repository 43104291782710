import { LinearProgress } from "@sobrus-com/sobrus-design-system";
import React, { Component } from 'react';
import { images } from "../../Themes/Images";
import "./LoadingPage.scss";

class LoadingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="LoadingPage__container">
                <img className="LoadingPage__img" style={{ width: 122.57, height: 37.91 }} src={images?.logo}></img>
                <div className="loader-container">
                    <LinearProgress className={`custom-linear-progress-valid`} value={90} />
                </div>
                <p className="LoadingPage__desc">Chargement de Sobrus MED</p>
            </div>
        );
    }
}

export default LoadingPage;