var BASE_URL = "https://api.med.sobrus.ovh";
var FRONT_BASE_URL = "https://med.sobrus.ovh";
var MANAGE_PROFILE_URL = "https://account.sobrus.ovh";
var LOGIN_URL = "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.med.sobrus.ovh/auth/token&prompt=login";
var UPLOADED_FILES_HEADER = "https://api.med.sobrus.ovh/uploaded_files/headers"
var PRODUCT_IMAGE_URL = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/"
var FILES_BASE_URL = "https://api.med.sobrus.ovh/uploaded_files/patients_files/"
var DOC_IMAGE_BASE_URL = 'https://api.med.sobrus.ovh/uploaded_files/doctors_profile_pictures/';
var ESTABLISHMENT_IMAGES_BASE_URL = 'https://api.med.sobrus.ovh/uploaded_files/healthcare_establishment_pictures/';

if (process.env.REACT_APP_ENV === "development") {
    // console.warn = function () {
    //     console.clear()
    // }
    // console.log = function () {
    //     console.clear()

    // }
    // console.error = function () {
    //     console.clear()

    // }
} else if (process.env.REACT_APP_ENV === "production") {
    BASE_URL = "https://api.med.sobrus.com";
    FRONT_BASE_URL = "https://med.sobrus.com";
    MANAGE_PROFILE_URL = "https://account.sobrus.com";
    LOGIN_URL = "https://api.account.sobrus.com/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.med.sobrus.com/auth/token"
    UPLOADED_FILES_HEADER = "https://api.med.sobrus.com/uploaded_files/headers"
    PRODUCT_IMAGE_URL = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/"
    FILES_BASE_URL = "https://api.med.sobrus.com/uploaded_files/patients_files/"
    DOC_IMAGE_BASE_URL = 'https://api.med.sobrus.com/uploaded_files/doctors_profile_pictures/';
    ESTABLISHMENT_IMAGES_BASE_URL = 'https://api.med.sobrus.com/uploaded_files/healthcare_establishment_pictures/';
    // console.warn = function () {
    //     console.clear()
    // }
    // console.log = function () {
    //     console.clear()

    // }
    // console.error = function () {
    //     console.clear()

    // }
}

export { BASE_URL, FRONT_BASE_URL, MANAGE_PROFILE_URL, LOGIN_URL, UPLOADED_FILES_HEADER, PRODUCT_IMAGE_URL, FILES_BASE_URL, DOC_IMAGE_BASE_URL, ESTABLISHMENT_IMAGES_BASE_URL }