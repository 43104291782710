
import Api from "./Api";

class productsService {
    getAllSuggestions = (params = {}, data = {}) => Api.post(`/api/products/suggestions/get`, params, data);
    getAll = (params = {}, data = {}, favorite = false) => {
        if (favorite) {
            return Api.post(`/api/products/get-favorites`, params, data);
        } else {
            return Api.post(`/api/products/get`, params, data);
        }
    }
    getAllPrescribed = (id, params = {}) => Api.post(`/api/patients/${id}/prescribed-products/get`, params);
    getElementById = (id, params = {}) => Api.get(`/api/products/${id}`, params);
    getProductDosages = (id, params = {}) => Api.get(`/api/products/${id}/dosages`, params);
    addProductDosages = (params = {}, data = {}) => Api.post(`/api/products/product-usages`, params, data);
    setProductDosageStatus = (id, params = {}, data = {}) => Api.delete(`/api/products/product-usages/${id}`, params, data);
    toggleFavoriteProduct = (id, params = {}) => Api.post(`/api/products/${id}/toggle-favorite`, params);

}
export default new productsService();