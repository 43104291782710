
const initialState = {
    diseases: [],
    loadingDiseases: false,
};

function diseasesReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_DISEASES":
            nextState = {
                ...state,
                diseases: action.value,
            };
            return nextState || state;
        case "LOADING_DISEASES":
            nextState = {
                ...state,
                loadingDiseases: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setDiseases = (value) => ({ type: "SET_DISEASES", value })
const setLoadingDiseases = (value) => ({ type: "LOADING_DISEASES", value })

// export variables
export { setDiseases, setLoadingDiseases };
export default diseasesReducer;