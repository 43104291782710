import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  dataOption: {},
};

const tableData = createSlice({
  name: 'dataOption',
  initialState,
  reducers: {
    /** create  */
    createOptionsStorageName: (state, action) => {
      state.dataOption = {
        ...state.dataOption,
        [action.payload]: [],
      };
    },
    setOptions: (state, action) => {
      const [key] = Object.keys(action.payload);
      const [value] = Object.values(action.payload);
      state.dataOption[key] = value;
    },
  },
});

export const { setOptions, createOptionsStorageName } = tableData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default tableData.reducer;
