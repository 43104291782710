import React, { Component } from "react";
import AppNavigation from './Navigation/AppNavigation';
import { Provider } from "react-redux";
import Store from "./Store/createStore";
import authController from "./Controllers/auth.controller";
import AppConfig from "./Config/AppConfig";
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import RotateScreenMessage from "./Components/RotateScreenMessage";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './App.scss';


Sentry.init({
  dsn: "https://e45fcc13dc784bc498a26244c88ea161@o1046785.ingest.sentry.io/6311533",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    authController.authentication().then(() => {
      // preload all inital data must be loaded
      AppConfig.preloadInitialData();
    });
    console.log('new Date', new Date());
  }
  render() {
    return (
      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>
          <Provider store={Store}>
            <AppNavigation />
          </Provider>
        </Orientation>
        <Orientation orientation='portrait' alwaysRender={false}>
          <RotateScreenMessage />
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default App;
