const initialState = {
    data: [],
    page: 1,
    lastPageNumber: 9999999,

    alreadyMounted: false,
};

function radiologicalAssessmentsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_RADIOLOGICAL_ASSESSMENTS_DATA":
            nextState = {
                ...state,
                data: action.value,
            };
            return nextState || state;
        case "SET_RADIOLOGICAL_ASSESSMENTS_PAGE":
            nextState = {
                ...state,
                page: action.value,
            };
            return nextState || state;
        case "SET_RADIOLOGICAL_ASSESSMENTS_LAST_PAGE":
            nextState = {
                ...state,
                lastPageNumber: action.value,
            };
            return nextState || state;
        case "SET_RADIOLOGICAL_ASSESSMENTS_TOTAL":
            nextState = {
                ...state,
                total: action.value,
            };
            return nextState || state;
        case "SET_RADIOLOGICAL_ASSESSMENTS_ALREADY_MOUNTED":
            nextState = {
                ...state,
                alreadyMounted: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default radiologicalAssessmentsReducer;