export const freemiumActions = [
    { name: "get_list_of_all_posts_with_pagination" },
    { name: "get_one_post_by_its_id" },
    { name: "create_new_product_usage" },
    { name: "get_list_of_all_product_dosages" },
    { name: "edit_product_usage" },
    { name: "deactivate_product_usage" },
    { name: "suggest_new_product_or_product_modification" },
    { name: "get_list_of_all_products_suggestions_with_pagination_and_filters" },
    { name: "get_one_products_suggestion_by_its_id" },
    { name: "get_list_of_all_products_with_pagination_and_filters" },
    { name: "get_one_product_by_its_id" },
    { name: "get_list_of_all_prescriptions_with_pagination_and_filters" },
    { name: "get_one_prescription_by_its_id" },
    { name: "create_new_prescription" },
    { name: "edit_prescription" },
    { name: "get_list_of_all_prescriptions_models_with_pagination_and_filters" },
    { name: "get_one_prescriptions_model_by_its_id" },
    { name: "create_new_prescriptions_model" },
    { name: "edit_prescriptions_model" },
    { name: "deactivate_prescriptions_model" },
    { name: "create_patient_multi_measurements_at_once" },
    { name: "get_list_of_all_patients_with_pagination_and_filters" },
    { name: "get_list_of_patients_filtered_by_fullname" },
    { name: "create_new_patient" },
    { name: "get_one_patient_by_its_id" },
    { name: "edit_patient" },
    { name: "deactivate_patient" },
    { name: "get_patient_prescribed_products_related_list" },
    { name: "get_my_connection_history" },
    { name: "get_establishment_params" },
    { name: "get_prescription_params" },
    { name: "get_list_of_all_patient_prescriptions" },
    { name: "create_new_prescription" },
    { name: "get_one_prescription_by_its_id" },
    { name: "edit_prescription" },
    { name: "get_list_of_all_antecedents_with_pagination_and_filters" },
    { name: "get_one_antecedent_by_its_id" },
    { name: "create_new_antecedent" },
    { name: "get_list_of_my_marketplace_orders" },
    { name: "get_marketplace_order_by_its_id" },
    { name: "create_new_marketplace_order" },
    { name: "change_order_status" },
    { name: "get_list_of_marketplace_offers" },
    { name: "add_action_to_marketplace_offers" },
    { name: "get_marketplace_offer_by_its_id" },

]

