/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-dupe-keys */
export default {
    Home: {
        list: "/"
    },
    Login: {
        list: "/login"
    },
    authResponse: {
        successResponse: "/auth/success",
        failureResponse: "/auth/failed",
        profile: "/account_details",
        countryVerification: "/auth/country-verification",
    },
    WaitingRoom: {
        list: "/waitingroom"
    },
    Calendar: {
        list: "/calendar",
        details: "/calendar-details",
    },
    Products: {
        list: "/products",
        details: "/products/details",
    },
    Colleagues: {
        list: "/colleagues",
        add: "/colleagues/create",
        details: "/colleagues/details",
        update: "/colleagues/update",
    },
    ProductsSugg: {
        list: "/products/suggestions",
        details: "/products/suggestions/details",
    },
    Ordinances: {
        list: "/ordonnances",
        add: "/ordonnances/create",
        update: "/ordonnances/update",
        duplicate: "/ordonnances/duplicate",
        details: "/ordonnances/view",
        prescriptionPdf: "/ordonnances/view/print"
    },
    biologicalPrescriptions: {
        list: "/biological_prescriptions",
        add: "/biological_prescriptions/create",
        update: "/biological_prescriptions/update",
        duplicate: "/biological_prescriptions/duplicate",
        details: "/biological_prescriptions/view",
        prescriptionPdf: "/biological_prescriptions/view/print"
    },
    prostheticPrescriptions: {
        list: "/prosthetic_prescriptions",
        add: "/prosthetic_prescriptions/create",
        update: "/prosthetic_prescriptions/update",
        duplicate: "/prosthetic_prescriptions/duplicate",
        details: "/prosthetic_prescriptions/view",
        prescriptionPdf: "/prosthetic_prescriptions/view/print"
    },
    RadiologicalAssessments: {
        list: "/radiological_assessments",
        add: "/radiological_assessments/create",
        update: "/radiological_assessments/update",
        duplicate: "/radiological_assessments/duplicate",
        details: "/radiological_assessments/view",
        prescriptionPdf: "/radiological_assessments/view/print"
    },
    prescriptionModels: {
        list: "/ordonnances/models",
        add: "/ordonnances/models/create",
        details: "/ordonnances/models/view",
        update: "/ordonnances/models/update",

    },
    Documents: {
        list: "/documents",
        add: "/documents/generate",
        send: "/documents/send-to-colleague",
    },
    Patients: {
        list: "/patients",
        details: "/patients/details",
        update: "/patients/edit",
        add: "/patients/create",
        export: "/patients/export",
        exportSelectOptions: "/patients/export-patients/select-options",
    },
    Appointments: {
        list: "/Appointments",
        details: "/Appointments/details",
        update: "/Appointments/edit",
        add: "/Appointments/create",
    },
    DentalCare: {
        list: "/dental-care",
        details: "/dental-care/details",
        add: "/dental-care/add",
        update: "/dental-care/edit",
    },
    Consultations: {
        list: "/consultations",
        details: "/consultations/details",
        add: "/consultations/add",
        update: "/consultations/edit",
    },
    Invoices: {
        list: "/invoices",
        details: "/invoices/details",
        update: "/invoices/edit",
        add: "/invoices/create",
        addDentelcareInvoice: "/dental-care/invoices/create",
        addPayments: "/invoices/payment",
        addPaymentsDetails: "/invoices/payment-details",
        exportSales: "/invoices/exportsales/select-options"
    },
    Quote: {
        list: "/quotes",
        details: "/quotes/details",
        pdf: "/quotes/pdf",
        update: "/quotes/edit",
        add: "/quotes/create",
        addPayments: "/quotes/payment",
        exportSales: "/quotes/exportsales/select-options"
    },
    Reports: {
        list: "/reports",
        details: "/reports/details",
        patients: "/reports/patients",
        consultaion: "/reports/consultaion",
        appointments: "/reports/appointments",
        dentalTreatments: "/reports/dental-treatments",
    },
    Settings: {
        list: "/settings/user/view",
        userLoging: "/settings/user/logins",

        prescriptionDetails: "/settings/pdf/prescription",
        prescriptionUpdate: "/settings/pdf/prescription-edit",
        invoicepPdfsParamsView: "/settings/pdf/invoice",
        invoicepPdfsParamsUpdate: "/settings/pdf/invoice-edit",
        pdfsParamsView: "/settings/pdf/params-view",
        pdfsParamsUpdate: "/settings/pdf/params-edit",

        users: "/settings/users",
        appointmenttypes: "/settings/appointment-types",
        appointmentAverageTime: "/settings/appointment-average-time",
        fieldsList: "/settings/fields-list",
        consultationtabs: "/settings/consultation-tabs",
        consultationtabs: "/settings/consultation-tabs",
        aclRoles: "/settings/acl-roles",
        aclMethods: "/settings/acl-methods",
        invoiceTypes: "/settings/invoice-type",
        prestations: "/settings/prestations",
        invoiceSeries: "/settings/invoice-serie",
        quotationSeries: "/settings/quotation-serie",

        documents: "/settings/documents-models",
        documentAddOrUpdate: "/settings/document-model-add-or-update",

        vaccin: "/settings/vaccins",
        vaccinAddOrUpdate: "/settings/vaccins-add-or-update",

        doctorGeneralPresentation: "/settings/doctor/general-presentation",
        doctorProfessionalCurriculum: "/settings/doctor/professional-curriculum",

        establishmentGeneralPresentation: "/settings/establishment/general-presentation",
        establishmentPictures: "/settings/establishment/pictures",
        establishmentOpeningHours: "/settings/establishment/opening-hours",

        dentaleCareSevicesTypes: "/settings/dental/care-services-types",
        dentaleCareSevices: "/settings/dental/care-services",
        dentaleCareSevicesTypes: "/settings/dental/care-services-types",
        dentaleCareSevices: "/settings/dental/care-services",
        dentaleCareFamilyPathologies: "/settings/dental/family-pathologies",
        dentaleCarePathologies: "/settings/dental/pathologies",
    },
    Spent: {
        list: "/spents",
        details: "/spents/details",
        add: "/spents/create",
    },
    Offers: {
        list: "/offers",
        details: "/offers/details/:id",
    },
    Orders: {
        list: "/orders",
        details: "/orders/details",
    },
    Common: {
        contact_us: "/contact_us",
        error_404: "/404",
        unauthorized: "/unauthorized"
    },

}
