import homeController from "Controllers/home.controller";
import dashboardService from "Services/dashboard.service";
import { setStatisticsReducer } from "Store/Reducers/statistics.reducer";
import commonService from "../Services/common.service";
import Store from "../Store/createStore";
import { setLoadingEstablishmentParamsForUsage, setEstablishmentParamsForUsage } from "../Store/Reducers/establishmentParamsForUsage.reducer";
import { setInvoiceParamsForUsage, setLoadingInvoiceParamsForUsage } from "../Store/Reducers/invoiceParamsForUsage.reducer";
const { dispatch } = Store;

const INITIAL_POSTS_LIMIT = 5;

class AppConfig {
  static preloadInitialData() {
    this.establishmentParamsForUsageReducer();
  }
  static async establishmentParamsForUsageReducer() {
    try {
      const params = {};
      dispatch(setLoadingEstablishmentParamsForUsage(true))
      const response = await commonService.getEstablishmentParamsForUsage(params);
      const invoiceResponse = await commonService.getInvoiceParamsForUsage(params);
      const statisticsData = await dashboardService.getAll({ page: 1, limit: INITIAL_POSTS_LIMIT });
      dispatch(setStatisticsReducer(statisticsData?.data))
      dispatch(setEstablishmentParamsForUsage(response?.data))
      dispatch(setLoadingEstablishmentParamsForUsage(false))

      dispatch(setInvoiceParamsForUsage(invoiceResponse?.data))
      dispatch(setLoadingInvoiceParamsForUsage(false))
    } catch (error) {
      dispatch(setLoadingEstablishmentParamsForUsage(false))
      console.log(error)
    }
  }

  static isDentist() {
    const { establishmentParamsForUsage, loadingEstablishmentParamsForUsage, } = Store.getState().ESTABLISHMENT_PARAMS_FOR_USAGE;

    if (establishmentParamsForUsage) {
      const DENTIST_IDS = [410, 428, 459, 483];
      return DENTIST_IDS.some((item) => item == establishmentParamsForUsage?.speciality?.id);
    }
    return false;
    // return true;
  }

}




export default AppConfig;