
const initialState = {
    treatedTeeth: [],
    selectedItem: [],
    deletedTreatments: [],

};

function treatedTeethReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "ADD_TREATED_TEETH":
            nextState = {
                ...state,
                treatedTeeth: [...state.treatedTeeth, ...action.value],
            };
            return nextState || state;
        case "SET_ALL_TREATED_TEETH":
            nextState = {
                ...state,
                treatedTeeth: action.value,
            };
            return nextState || state;
        case "ADD_DELETED_TREATMENT_ITEM":
            nextState = {
                ...state,
                deletedTreatments: [...state.deletedTreatments, action.value],
            };
            return nextState || state;
        case "SET_ALL_DELETED_TREATMENTS":
            nextState = {
                ...state,
                deletedTreatments: action.value,
            };
            return nextState || state;
        case "SET_SELECTED_TEETH":
            nextState = {
                ...state,
                selectedItem: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const addTreatedTeeth = (value) => ({ type: "ADD_TREATED_TEETH", value })
const selAllTreatedTeeth = (value) => ({ type: "SET_ALL_TREATED_TEETH", value })
const setSelectedItem = (value) => ({ type: "SET_SELECTED_TEETH", value })

const addDeletedTreatment = (value) => ({ type: "ADD_DELETED_TREATMENT_ITEM", value })
const setAllDeletedTreatment = (value) => ({ type: "SET_ALL_DELETED_TREATMENTS", value })


// export variables
export { addTreatedTeeth, setSelectedItem, selAllTreatedTeeth, addDeletedTreatment, setAllDeletedTreatment };
export default treatedTeethReducer;