
const initialState = {
    step1: null,
    step2: null,
    step3: null,

    loadingAuthenticatedUser: false,
};

function patientAddUpdateReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_STEP_1":
            nextState = {
                ...state,
                step1: action.value,
            };
            return nextState || state;
        case "SET_STEP_2":
            nextState = {
                ...state,
                step2: action.value,
            };
            return nextState || state;
        case "SET_STEP_3":
            nextState = {
                ...state,
                step3: action.value,
            };
            return nextState || state;
        case "RESET_PATIENT_ADD_UPDATE_DATA":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setPatientAddUpdateStep = (step, value) => ({ type: `SET_STEP_${step}`, value })
const resetPatientAddUpdateData = () => ({ type: `RESET_PATIENT_ADD_UPDATE_DATA`, value: {} })


// export variables
export { setPatientAddUpdateStep, resetPatientAddUpdateData };
export default patientAddUpdateReducer;