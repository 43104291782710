const initialState = {
    closeDentalCare: false,
};

function closeDentalCareReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_CLOSE_DENTAL_CARE":
            nextState = {
                ...state,
                closeDentalCare: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default closeDentalCareReducer;