
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "./Reducers/auth.reducer";
import patientAddUpdateReducer from "./Reducers/patientAddUpdate.reducer";
import establishmentParamsForUsageReducer from "./Reducers/establishmentParamsForUsage.reducer";
import calendarFilterReducer from "./Reducers/calendarFilter.reducer";
import diseasesReducer from "./Reducers/diseases.reducer";
import consultationAddUpdateReducer from "./Reducers/consultationAddUpdate.reducer";
import patientsReducer from "./Reducers/patients.reducer";
import productsReducer from "./Reducers/products.reducer";
import prescriptionsReducer from "./Reducers/prescriptions.reducer";
import biologicalPrescriptionsReducer from "./Reducers/biologicalPrescription.reducer";
import consultationsReducer from "./Reducers/consultations.reducer";
import invoicesReducer from "./Reducers/invoices.reducer";
import documentsReducer from "./Reducers/documents.reducer";
import postsReducer from "./Reducers/posts.reducer";
import invoiceParamsForUsage from "./Reducers/invoiceParamsForUsage.reducer";
import expensesReducer from "./Reducers/expenses.reducer";
import treatedTeethReducer from "./Reducers/treatedTeeth.reducer";
import colleaguesReducer from "./Reducers/colleagues.reducer";
import radiologicalAssessmentsReducer from "./Reducers/radiologicalAssessments.reducer";
import dentalPathologiesReducer from "./Reducers/dentalPathologies.reducer";
import dentalUIReducer from "./Reducers/dentalUi.reducer";
import quotesReducer from "./Reducers/quotes.reducer";
import closeDentalCareReducer from "./Reducers/closeDentalCare.reducer";
import ProstheticPrescriptionsReducer from "./Reducers/ProstheticPrescriptions.reducer";
import statisticsReducer from "./Reducers/statistics.reducer";
import optionReducer from './Reducers/optionSlice';
import offersReducer from './Reducers/offersSlice';
import ordersReducer from "./Reducers/orders.reducer";

const rootReducer = combineReducers({
    AUTH: authReducer,
    PATIENT_ADD_UPDATE: patientAddUpdateReducer,
    ESTABLISHMENT_PARAMS_FOR_USAGE: establishmentParamsForUsageReducer,
    CALENDAR_FILTER: calendarFilterReducer,
    DISEASES: diseasesReducer,
    CONSULTATIONS_ADD_UPDATE: consultationAddUpdateReducer,
    PATIENTS: patientsReducer,
    PRODUCTS: productsReducer,
    PRESCRIPTIONS: prescriptionsReducer,
    RADIOLOGICAL_ASSESSMENTS: radiologicalAssessmentsReducer,
    BIOLOGICALPRESCRIPTIONS: biologicalPrescriptionsReducer,
    PROSTHETICPRESCRIPTIONS: ProstheticPrescriptionsReducer,
    CONSULATIONS: consultationsReducer,
    INVOICES: invoicesReducer,
    DOCUMENTS: documentsReducer,
    POSTS: postsReducer,
    INVOICE_PARAMS_FOR_USAGE: invoiceParamsForUsage,
    EXPENSES: expensesReducer,
    TREATED_TEETH: treatedTeethReducer,
    DENTAL_PATHOLOGIES: dentalPathologiesReducer,
    COLLEAGUES: colleaguesReducer,
    DENTAL_UI: dentalUIReducer,
    QUOTES: quotesReducer,
    CLOSEDENTALCARE: closeDentalCareReducer,
    STATISTICS: statisticsReducer,
    dataOptions: optionReducer,
    offers: offersReducer,
    Orders: ordersReducer,
});
export default createStore(rootReducer, applyMiddleware(thunk));
