const initialState = {
    dashboardKeyFigures: {
        totalPrescriptions: 0,
        totalPatients: 0,
        totalProducts: 0
    }
};

function statisticsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_STATISTICS":
            nextState = {
                ...state,
                dashboardKeyFigures: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setStatisticsReducer = (value) => ({ type: "SET_STATISTICS", value })

// export variables
export { setStatisticsReducer, };
export default statisticsReducer;