const initialState = {
    data: [],
    page: 1,
    lastPageNumber: 999999999,
    archivedFilter: false,
    alreadyMounted: false,
};

function patientsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_PATIENT_DATA":
            nextState = {
                ...state,
                data: action.value,
            };
            return nextState || state;
        case "SET_PATIENT_PAGE":
            nextState = {
                ...state,
                page: action.value,
            };
            return nextState || state;
        case "SET_PATIENT_ARCHIVED_FILTER":
            nextState = {
                ...state,
                archivedFilter: action.value,
            };
            return nextState || state;
        case "SET_PATIENT_LAST_PAGE":
            nextState = {
                ...state,
                lastPageNumber: action.value,
            };
            return nextState || state;
        case "SET_PATIENT_TOTAL":
            nextState = {
                ...state,
                total: action.value,
            };
            return nextState || state;
        case "SET_PATIENT_ALREADY_MOUNTED":
            nextState = {
                ...state,
                alreadyMounted: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default patientsReducer;