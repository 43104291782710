import axios from "axios";
import axiosRetry from "axios-retry";
import authService from "./auth.service";
import messageText from "../Values/messageText";
import { BASE_URL, LOGIN_URL } from "env";
// import HttpErrors from "./HttpErrors";
// import authDAO from "./authDAO";
// import routesPath from "../values/routesPath";
// import { CODES } from "../values/translate";
// import { BASE_URL } from "../env";

const API = axios.create({
    baseURL: `${BASE_URL}/`,
    responseType: "json",
    withCredentials: true,
});
API.all = axios.all

// Response interceptor for API calls
// axiosRetry(API, { retryDelay: axiosRetry.exponentialDelay });
// axiosRetry(API, { retries: 3 });



API.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (401 === error?.response?.status) {
            if (error?.response?.data?.message === "code1") {
                localStorage.clear();
                window.location.href = LOGIN_URL
            } else if (error?.response?.data?.message === "code2") {
                const originalRequest = error.config;
                try {
                    await authService.refresh();
                    window.location.reload();
                } catch (error) {
                    console.log(error)
                }
            } else if (error?.response?.data?.message === "code3") {
                localStorage.clear();
                window.location.href = LOGIN_URL
            }
        } else {
            return Promise.reject(error);
        }
    }
);

API.interceptors.request.use(async function (config) {
    // config.headers["X-CSRF-Token"] = getCookie("csrfToken");
    return config;
});

const HEADER = {
    "Content-type": "application/json",
};

class Api {
    constructor(props) { }
    static get = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.get(url, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
    static get_v2 = (url, params) => {
        return API.get(url, {
            params: params,
            headers: HEADER,
        });
    }
    static all = (array) => {
        return API.all(array);
    }

    static post = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.post(url, data, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });


    static patch = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.patch(url, data, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });

    static upload = (url, params, data, options) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "multipart/form-data",
                };
                let response = await API.post(url, data, {
                    params: params,
                    headers: header,
                    ...options,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
    static delete = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "multipart/form-data",
                };
                let response = await API.delete(url, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
}

const handleError = (error, reject) => {
    console.log("error error error error", error.response)
    if (error?.response?.data?.message?.includes("prescription_product_unique")) {
        error.message = "Impossible de prescrire le même produit plusieurs fois";
    }
    if (error?.message == "Network Error") {
        error.message = "Aucune réponse reçue, merci de vérifier votre connexion";
    }
    reject(error)
};

export default Api;