import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import LoadingPage from '../Pages/loadingPage';
import routesPath from '../Values/routesPath';
import { acl } from './Acl';
import Transition from './RouteTransition/Transition';
import { Review } from '@sobrus-com/sobrus-surveys-package';
import { BASE_URL } from 'env';


const mapStateToProps = (state) => {
  return {
    authenticatedUser: state.AUTH.authenticatedUser,
    loadingAuthenticatedUser: state.AUTH.loadingAuthenticatedUser,
    establishmentParamsForUsage: state.ESTABLISHMENT_PARAMS_FOR_USAGE.establishmentParamsForUsage,
    loadingEstablishmentParamsForUsage: state.ESTABLISHMENT_PARAMS_FOR_USAGE.loadingEstablishmentParamsForUsage,
  };
};

const PrivateRoute = ({ component: Component, path, children, aclActions, provider: Provider, ...rest }) => {
  const { authenticatedUser, loadingAuthenticatedUser } = rest;
  const { establishmentParamsForUsage, loadingEstablishmentParamsForUsage } = rest;
  if (!loadingAuthenticatedUser) {
    if (authenticatedUser) {
      if (!loadingEstablishmentParamsForUsage && establishmentParamsForUsage) {
        // manage acl
        if (acl(aclActions ? { actions: aclActions } : { allowed: true })) {
          return (
            <>
              <Navbar />
              <Route
                {...rest}
                render={(props) => {
                  return (
                    <Transition>
                      <Suspense fallback={<LoadingPage />}>
                        <Review baseUrl={BASE_URL} backgroundColor="#008e7c" />
                        <Component {...props} />
                      </Suspense>
                    </Transition>
                  );
                }}
              />
            </>
          );
        } else {
          // redirect to unauthorized page
          return (
            <Redirect
              to={{
                pathname: `${routesPath.Common.unauthorized}`,
                state: {
                  from: rest.location,
                },
              }}
            />
          );
        }
      } else {
        return <LoadingPage />;
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: `${routesPath.Login.list}`,
            state: {
              from: rest.location,
            },
          }}
        />
      );
    }
  } else {
    return <LoadingPage />;
  }
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
