import authService from "../Services/auth.service";
import { setLoadingAuthenticatedUser, setAuthenticatedUser } from "../Store/Reducers/auth.reducer";
import Store from "../Store/createStore";
const { dispatch } = Store;

class authController {
    authentication = () => new Promise(async (resolve, reject) => {
        try {
            dispatch(setLoadingAuthenticatedUser(true))
            const response = await authService.getAuthenticatedUser();
            if (response) {
                dispatch(setAuthenticatedUser(response?.data ?? null))
            }
            dispatch(setLoadingAuthenticatedUser(false));
            resolve();
        } catch (error) {
            dispatch(setLoadingAuthenticatedUser(false))
            reject();
        }
    })

}
export default new authController();