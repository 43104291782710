import React, { Component } from 'react';
import { MdScreenRotation } from "react-icons/md";

class RotateScreenMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <MdScreenRotation style={{ marginBottom: 20 }} color="" size={60} />
                <p>Please rotate your device</p>
            </div>
        );
    }
}

export default RotateScreenMessage;