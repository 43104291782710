const initialState = {
    data: [],
    page: 1,
    lastPageNumber: 9999999,
    favoriteFilter: false,
    alreadyMounted: false,
};

function productsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_PRODUCT_DATA":
            nextState = {
                ...state,
                data: action.value,
            };
            return nextState || state;
        case "SET_PRODUCT_PAGE":
            nextState = {
                ...state,
                page: action.value,
            };
            return nextState || state;
        case "SET_PRODUCT_FAVORITE_FILTER":
            nextState = {
                ...state,
                favoriteFilter: action.value,
            };
            return nextState || state;
        case "SET_PRODUCT_LAST_PAGE":
            nextState = {
                ...state,
                lastPageNumber: action.value,
            };
            return nextState || state;
        case "SET_PRODUCT_TOTAL":
            nextState = {
                ...state,
                total: action.value,
            };
            return nextState || state;
        case "SET_PRODUCT_ALREADY_MOUNTED":
            nextState = {
                ...state,
                alreadyMounted: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default productsReducer;