const initialState = {
    data: [],
    page: 1,
    total: 0,
    alreadyMounted: false,
};

function colleaguesReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_COLLEAGUE_DATA":
            nextState = {
                ...state,
                data: action.value,
            };
            return nextState || state;
        case "SET_COLLEAGUE_PAGE":
            nextState = {
                ...state,
                page: action.value,
            };
            return nextState || state;
        case "SET_COLLEAGUE_TOTAL":
            nextState = {
                ...state,
                total: action.value,
            };
            return nextState || state;
        case "SET_COLLEAGUE_ALREADY_MOUNTED":
            nextState = {
                ...state,
                alreadyMounted: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default colleaguesReducer;