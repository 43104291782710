const initialState = {
    data: [],
    page: 1,
    lastPageNumber: 9999999,

    alreadyMounted: false,
};

function ordersReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_ORDERS_DATA":
            nextState = {
                ...state,
                data: action.value,
            };
            return nextState || state;
        case "SET_ORDERS_PAGE":
            nextState = {
                ...state,
                page: action.value,
            };
            return nextState || state;
        case "SET_ORDERS_LAST_PAGE":
            nextState = {
                ...state,
                lastPageNumber: action.value,
            };
            return nextState || state;
        case "SET_ORDERS_TOTAL":
            nextState = {
                ...state,
                total: action.value,
            };
            return nextState || state;
        case "SET_ORDERS_ALREADY_MOUNTED":
            nextState = {
                ...state,
                alreadyMounted: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default ordersReducer;