import React from 'react';
import { connect } from "react-redux";
import Store from "../Store/createStore";
import { freemiumActions } from '../Values/freemiumActions';
export const isAssistant = () => {
    const { authenticatedUser, loadingAuthenticatedUser, } = Store.getState().AUTH;
    if (!loadingAuthenticatedUser && authenticatedUser?.role && authenticatedUser?.role?.label && String(authenticatedUser?.role?.label).toLocaleLowerCase() === "assistant(e)") {
        return true;
    }
    return false;
}
export const isPremiumAccount = () => {
    const { authenticatedUser, loadingAuthenticatedUser, } = Store.getState().AUTH;
    if (!loadingAuthenticatedUser && authenticatedUser?.subscriptionType && String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "premium") {
        return true;
    }
    return false;
}
export const isAdmin = () => {
    const { authenticatedUser, loadingAuthenticatedUser, } = Store.getState().AUTH;
    if (!loadingAuthenticatedUser && authenticatedUser?.isAdmin) return true;
    return false;
}

export const PremiumAccount = ({ children, ...props }) => {
    if (isPremiumAccount()) {
        return (
            children
        );
    } else {
        return (<></>);
    }
}

const Acl = ({ children, actions, allowed, ...props }) => {
    const can = (actions) => {
        const { authenticatedUser, loadingAuthenticatedUser, } = props;

        if (allowed) return true;
        if (!actions || actions?.length === 0) return false;
        if (!loadingAuthenticatedUser && authenticatedUser?.subscriptionType && String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "premium") {
            if (!loadingAuthenticatedUser && authenticatedUser?.isAdmin) return true;
            if (!loadingAuthenticatedUser && !authenticatedUser?.role) return false;
            if (!loadingAuthenticatedUser && (!authenticatedUser?.role?.allowedActions || authenticatedUser?.role?.allowedActions?.length == 0)) return false;

            if (!loadingAuthenticatedUser && authenticatedUser) {
                let AllowedActions = false;
                actions?.map((action, index) => {
                    let can = authenticatedUser?.role?.allowedActions.some((object) => String(object.name).toLocaleLowerCase() === String(action).toLocaleLowerCase());
                    if (can) {
                        AllowedActions = true;
                    }
                })
                return AllowedActions;
            }
        } else if (!loadingAuthenticatedUser && authenticatedUser?.subscriptionType && (String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "free" || String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "freemium")) {
            if (!loadingAuthenticatedUser && authenticatedUser) {
                let AllowedActions = false;
                actions?.map((action, index) => {
                    let can = freemiumActions.some((object) => String(object.name).toLocaleLowerCase() === String(action).toLocaleLowerCase());
                    if (can) {
                        AllowedActions = true;
                    }
                })
                return AllowedActions;
            }
        }

        return false;
    }

    if (can(actions ?? [])) {
        return (
            children
        );
    } else {
        return (<></>);
    }
    // return (
    //     children
    // );
}


export const acl = ({ actions, allowed }) => {
    const { authenticatedUser, loadingAuthenticatedUser, } = Store.getState().AUTH;
    if (allowed) return true;
    if (!actions || actions?.length === 0) return false;
    if (!loadingAuthenticatedUser && authenticatedUser?.subscriptionType && String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "premium") {
        if (!loadingAuthenticatedUser && authenticatedUser?.isAdmin) return true;
        if (!loadingAuthenticatedUser && !authenticatedUser?.role) return false;
        if (!loadingAuthenticatedUser && (!authenticatedUser?.role?.allowedActions || authenticatedUser?.role?.allowedActions?.length == 0)) return false;

        if (!loadingAuthenticatedUser && authenticatedUser) {
            let AllowedActions = false;
            actions?.map((action, index) => {
                let can = authenticatedUser?.role?.allowedActions.some((object) => String(object.name).toLocaleLowerCase() === String(action).toLocaleLowerCase());
                if (can) {
                    AllowedActions = true;
                }
            })
            return AllowedActions;
        }
    } else if (!loadingAuthenticatedUser && authenticatedUser?.subscriptionType && (String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "free" || String(authenticatedUser?.subscriptionType).toLocaleLowerCase() === "freemium")) {
        if (!loadingAuthenticatedUser && authenticatedUser) {
            let AllowedActions = false;
            actions?.map((action, index) => {
                let can = freemiumActions.some((object) => String(object.name).toLocaleLowerCase() === String(action).toLocaleLowerCase());
                if (can) {
                    AllowedActions = true;
                }
            })
            return AllowedActions;
        }
    }
    return false;
}

const mapStateToProps = (state) => {
    return ({
        authenticatedUser: state.AUTH.authenticatedUser,
        loadingAuthenticatedUser: state.AUTH.loadingAuthenticatedUser,
    })
}

export default connect(mapStateToProps)(Acl);



