
const initialState = {
    invoiceParamsForUsage: null,
    loadingInvoiceParamsForUsage: false,
};

function invoiceParamsForUsage(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_INVOICE_PARAMS_FOR_USAGE":
            nextState = {
                ...state,
                invoiceParamsForUsage: action.value,
            };
            return nextState || state;
        case "LOADING_INVOICE_PARAMS_FOR_USAGE":
            nextState = {
                ...state,
                loadingInvoiceParamsForUsage: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setInvoiceParamsForUsage = (value) => ({ type: "SET_INVOICE_PARAMS_FOR_USAGE", value })
const setLoadingInvoiceParamsForUsage = (value) => ({ type: "LOADING_INVOICE_PARAMS_FOR_USAGE", value })

// export variables
export { setInvoiceParamsForUsage, setLoadingInvoiceParamsForUsage };
export default invoiceParamsForUsage;