import React from 'react';
import './CancellationBanner.scss';

const CancellationBanner = () => {
  return (
    <div className='CancellationBanner'>
      <p>Votre licence est expirée, merci de nous contacter afin de renouveler votre licence et éviter toute limitation dans l'utilisation des fonctionnalités</p>
    </div>
  )
}

export default CancellationBanner