
const initialState = {
    calendarFilter: "all",
};

function calendarFilterReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_CALENDAR_FILTER":
            nextState = {
                ...state,
                calendarFilter: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setCalendarFilter = (value) => ({ type: "SET_CALENDAR_FILTER", value })

// export variables
export { setCalendarFilter, };
export default calendarFilterReducer;