import Store from "../Store/createStore";

// leave off @2x/@3x
const images = {
  logo: Store ? require('../Img/med_logo.png') : require('../Img/med_logo.png'),
  prescriptionWarning: require("../Img/Icons/prescription-warning-icon.png"),
  patientInfo: require("../Img/Icons/patient-info-icon.png"),
  invoicePrimary: require("../Img/Icons/invoice-primary-icon.png"),
  calendarDanger: require("../Img/Icons/calendar-danger-icon.png"),
  consultationSuccess: require("../Img/Icons/consultation-success-icon.png"),

  // launch: require('../Images/launch-icon.png'),
  // ready: require('../Images/your-app.png'),
  // ignite: require('../Images/ignite_logo.png'),
  // igniteClear: require('../Images/ignite-logo-transparent.png'),
  // tileBg: require('../Images/tile_bg.png'),
  // background: require('../Images/BG.png'),
  // buttonBackground: require('../Images/button-bg.png'),
  // api: require('../Images/Icons/icon-api-testing.png'),
  // components: require('../Images/Icons/icon-components.png'),
  // deviceInfo: require('../Images/Icons/icon-device-information.png'),
  // faq: require('../Images/Icons/faq-icon.png'),
  // home: require('../Images/Icons/icon-home.png'),
  // theme: require('../Images/Icons/icon-theme.png'),
  // usageExamples: require('../Images/Icons/icon-usage-examples.png'),
  // chevronRight: require('../Images/Icons/chevron-right.png'),
  // hamburger: require('../Images/Icons/hamburger.png'),
  // backButton: require('../Images/Icons/back-button.png'),
  // closeButton: require('../Images/Icons/close-button.png')
}
const imagesSize = {
  logo: Store ? { width: 102.57, height: 37.91 } : { width: 102.57, height: 37.91 },
  prescriptionWarning: { width: 48.18, height: 48.17 },
  patientInfo: { width: 44.2, height: 56 },
  invoicePrimary: { width: 41.5, height: 50.5 },
  calendarDanger: { width: 43.89, height: 43.89 },
  consultationSuccess: { width: 46.25, height: 46.25 },
  // clearLogo: require('../Images/top_logo.png'),
  // launch: require('../Images/launch-icon.png'),
  // ready: require('../Images/your-app.png'),
  // ignite: require('../Images/ignite_logo.png'),
  // igniteClear: require('../Images/ignite-logo-transparent.png'),
  // tileBg: require('../Images/tile_bg.png'),
  // background: require('../Images/BG.png'),
  // buttonBackground: require('../Images/button-bg.png'),
  // api: require('../Images/Icons/icon-api-testing.png'),
  // components: require('../Images/Icons/icon-components.png'),
  // deviceInfo: require('../Images/Icons/icon-device-information.png'),
  // faq: require('../Images/Icons/faq-icon.png'),
  // home: require('../Images/Icons/icon-home.png'),
  // theme: require('../Images/Icons/icon-theme.png'),
  // usageExamples: require('../Images/Icons/icon-usage-examples.png'),
  // chevronRight: require('../Images/Icons/chevron-right.png'),
  // hamburger: require('../Images/Icons/hamburger.png'),
  // backButton: require('../Images/Icons/back-button.png'),
  // closeButton: require('../Images/Icons/close-button.png')
}
export { images, imagesSize }
