
//for saving data between tabs

const initialState = {
    savedInfos: null,
};

function consultationAddUpdateReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_SAVED_INFOS_CONSULTATION_ADD_UPDATE":
            nextState = {
                ...state,
                savedInfos: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const saveInfos = (value) => ({ type: "SET_SAVED_INFOS_CONSULTATION_ADD_UPDATE", value })

// export variables
export { saveInfos, };
export default consultationAddUpdateReducer;