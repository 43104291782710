
import Api from "./Api";

class commonService {
    getAllCities = (params = {}) => Api.get(`/api/cities`, params);
    getAllSectors = (params,data) => Api.get(`/api/sectors`, {params,data});
    getAllPayers = (params = {}) => Api.get(`/api/payers`, params);
    getEstablishmentParamsForUsage = (params = {}) => Api.get(`/api/parameters/establishment-params-for-usage`, params);
    getAllDci = (params = {}, data = {}) => Api.post(`/api/product-params/dcis`, params, data);
    getAllTherapeuticClasses = (params = {}, data = {}) => Api.post(`/api/product-params/therapeutic-classes`, params, data);
    getAllLaboratory = (params = {}, data = {}) => Api.post(`/api/product-params/suppliers`, params, data);
    getAllMedicalTests = (params = {}, data = {}) => Api.post(`/api/medical-tests/get`, params, data);
    getAllMedicalImaging = (params = {}, data = {}) => Api.post(`/api/medical-imaging/get`, params, data);
    getMedicalTestsInstructions = (id, params = {}) => Api.get(`/api/medical-tests/${id}/instructions`, params);
    getMedicalImagingInstructions = (id, params = {}) => Api.get(`/api/medical-imaging/${id}/instructions`, params);
    addMedicalTestsInstructions = (params = {}, data = {}) => Api.post(`/api/medical-tests/instructions`, params, data);
    addMedicalImagingInstructions = (params = {}, data = {}) => Api.post(`/api/medical-imaging/instructions`, params, data);
    getPrescriptionParamsForUsage = (params = {}) => Api.get(`/api/parameters/prescriptions-params-for-usage`, params);
    getInvoiceParamsForUsage = () => Api.get(`/api/parameters/invoice-params-for-usage`, '', { withCredentials: true })
}
export default new commonService();