
const initialState = {
    viewControl: "initialState",
};

function dentalUIReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_VIEW_CONTROL":
            nextState = {
                ...state,
                viewControl: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setViewControl = (value) => ({ type: "SET_VIEW_CONTROL", value })


// export variables
export { setViewControl, };
export default dentalUIReducer;