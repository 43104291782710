const initialState = {
    data: [],
    page: 1,
    lastPageNumber: 9999999,

    alreadyMounted: false,
};

function ProstheticPrescriptionsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_PROSTHETIC_PRESCRIPTION_DATA":
            nextState = {
                ...state,
                data: action.value,
            };
            return nextState || state;
        case "SET_PROSTHETIC_PRESCRIPTION_PAGE":
            nextState = {
                ...state,
                page: action.value,
            };
            return nextState || state;
        case "SET_PROSTHETIC_PRESCRIPTION_LAST_PAGE":
            nextState = {
                ...state,
                lastPageNumber: action.value,
            };
            return nextState || state;
        case "SET_PROSTHETIC_PRESCRIPTION_TOTAL":
            nextState = {
                ...state,
                total: action.value,
            };
            return nextState || state;
        case "SET_PROSTHETIC_PRESCRIPTION_ALREADY_MOUNTED":
            nextState = {
                ...state,
                alreadyMounted: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default ProstheticPrescriptionsReducer;