import Axios from "axios";
import { BASE_URL } from "env";

const API = Axios.create({
    baseURL: `${BASE_URL}/`,
    responseType: "json",
    withCredentials: true,
});

class authService {
    getAuthenticatedUser = (params = {}) => API.get(`/api/users/authenticated-user`, params);
    countryVerification = (data = {}) => API.post(`/api/auth/token/complete-verification`, data, {});
    getUsers = (params) => API.post(`/api/users/get`, {}, params);

    logout = () => {
        localStorage.clear();
        this.authenticated = false;
        window.location.href = BASE_URL + "/auth/revoke";
    };
    refresh = (params = {}) => API.get(`/auth/refresh`, params);

}

export default new authService();