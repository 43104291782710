const INITIAL_POSTS_LIMIT = 5;

const initialState = {
    posts: [],
    totalPostsItems: 0,
    postsPage: 1,
    postsLimitItems: INITIAL_POSTS_LIMIT,
};



function postsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_POSTS_DATA":
            nextState = {
                ...state,
                posts: action.value,
            };
            return nextState || state;
        case "SET_POSTS_LIMIT_ITEMS":
            nextState = {
                ...state,
                postsLimitItems: action.value,
            };
            return nextState || state;
        case "SET_POST_TOTAL":
            nextState = {
                ...state,
                totalPostsItems: action.value,
            };
            return nextState || state;
        case "SET_POST_PAGE":
            nextState = {
                ...state,
                postsPage: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default postsReducer;