import React, { lazy, Suspense } from 'react';
import { Toast } from '@sobrus-com/sobrus-design-system';
import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from '../Helpers/PrivateRoute';
import { LoginTransition } from "../Helpers/RouteTransition/Transition";
import LoadingPage from '../Pages/loadingPage';
import aclActions from '../Values/aclActions.json';
import routesPath from '../Values/routesPath';

const Home = lazy(() => import(/* webpackChunkName: "MODULE-Home" */ '../Pages/Home/Home'));
const AccessDenied = lazy(() => import(/* webpackChunkName: "AccessDenied" */'../Pages/AccessDenied/AccessDenied'));
const ConsultationDetails = lazy(() => import(/* webpackChunkName: "ConsultationDetails" */'../Pages/ConsultationDetails'));
const Aclmethods = lazy(() => import(/* webpackChunkName: "Aclmethods" */'../Pages/AclMethods/AclMethods'));
const Aclroles = lazy(() => import(/* webpackChunkName: "Aclroles" */'../Pages/AclRoles/AclRoles'));
const Aclusers = lazy(() => import(/* webpackChunkName: "Aclusers" */'../Pages/ACLUsers/ACLUsers'));
const AppointmentAverageTime = lazy(() => import(/* webpackChunkName: "AppointmentAverageTime" */'../Pages/AppointmentAverageTime/AppointmentAverageTime'));
const AppointmentDetails = lazy(() => import(/* webpackChunkName: "AppointmentDetails" */'../Pages/AppointmentDetails'));
const Appointmenttypes = lazy(() => import(/* webpackChunkName: "Appointmenttypes" */'../Pages/AppointmentTypes/AppointmentTypes'));
const Approvefactures = lazy(() => import(/* webpackChunkName: "Approvefactures" */'../Pages/ApproveFactures/ApproveFactures'));
const PaymentDetailsInvoice = lazy(() => import(/* webpackChunkName: "Approvefactures" */'../Pages/ApproveFactures/PaymentDetailsInvoice'));
const BiologicalPrescriptionAddUpdate = lazy(() => import(/* webpackChunkName: "BiologicalPrescriptionAddUpdate" */'../Pages/BiologicalPrescriptionAddUpdate'));
const BiologicalPrescriptionDetails = lazy(() => import(/* webpackChunkName: "BiologicalPrescriptionDetails" */'../Pages/BiologicalPrescriptionDetails/BiologicalPrescriptionDetails'));
const BiologicalPrescriptionPDF = lazy(() => import(/* webpackChunkName: "BiologicalPrescriptionPDF" */'../Pages/BiologicalPrescriptionPDF/BiologicalPrescriptionPDF'));
const BiologicalPrescriptions = lazy(() => import(/* webpackChunkName: "BiologicalPrescriptions" */'../Pages/BiologicalPrescriptions'));
const Calendar = lazy(() => import(/* webpackChunkName: "Calendar" */'../Pages/Calendar'));
const ConsultationAddUpdate = lazy(() => import(/* webpackChunkName: "ConsultationAddUpdate" */'../Pages/ConsultationAddUpdate'));
const Consultations = lazy(() => import(/* webpackChunkName: "Consultations" */'../Pages/Consultations'));
const Consultationtabs = lazy(() => import(/* webpackChunkName: "Consultationtabs" */'../Pages/ConsultationTabs/ConsultationTabs'));
const CountryVerification = lazy(() => import(/* webpackChunkName: "CountryVerification" */'../Pages/CountryVerification/CountryVerification'));
const DocumentAddOrUpdate = lazy(() => import(/* webpackChunkName: "DocumentAddOrUpdate" */'../Pages/DocumentAddOrUpdate/DocumentAddOrUpdate'));
const DocumentsList = lazy(() => import(/* webpackChunkName: "DocumentsList" */'../Pages/DocumentsList/DocumentsList'));
const DocumentsModelsList = lazy(() => import(/* webpackChunkName: "DocumentsModelsList" */'../Pages/DocumentsModelsList/DocumentsModelsList'));
const FacturesExportsales = lazy(() => import(/* webpackChunkName: "FacturesExportsales" */'../Pages/FacturesExportsales/FacturesExportsales'));
const FailureResponse = lazy(() => import(/* webpackChunkName: "FailureResponse" */'../Pages/FailureResponse/FailureResponse'));
const Fieldslist = lazy(() => import(/* webpackChunkName: "Fieldslist" */'../Pages/FieldsList/FieldsList'));
const GenerateDocument = lazy(() => import(/* webpackChunkName: "GenerateDocument" */'../Pages/GenerateDocument/GenerateDocument'));
const Invoicespdf = lazy(() => import(/* webpackChunkName: "InvoicesPDF" */ '../Components/InvoicesPDF/InvoicesPDF'));
const Invoicecreate = lazy(() => import(/* webpackChunkName: "Invoicecreate" */'../Pages/invoiceCreate/invoiceCreate'));
const Invoiceppdfsparamsupdate = lazy(() => import(/* webpackChunkName: "Invoiceppdfsparamsupdate" */'../Pages/InvoicepPdfsParamsUpdate/InvoicepPdfsParamsUpdate'));
const Invoiceppdfsparamsview = lazy(() => import(/* webpackChunkName: "Invoiceppdfsparamsview" */'../Pages/InvoicepPdfsParamsView/InvoicepPdfsParamsView'));
const Invoicesdetails = lazy(() => import(/* webpackChunkName: "Invoicesdetails" */'../Pages/invoicesDetails/invoicesDetails'));
const Invoiceseries = lazy(() => import(/* webpackChunkName: "Invoiceseries" */'../Pages/InvoiceSeries/InvoiceSeries'));
const Invoiceslist = lazy(() => import(/* webpackChunkName: "Invoiceslist" */'../Pages/InvoicesList/InvoicesList'));
const Invoicetypes = lazy(() => import(/* webpackChunkName: "Invoicetypes" */'../Pages/InvoiceTypes/InvoiceTypes'));
const AddDentelcareInvoice = lazy(() => import(/* webpackChunkName: "AddDentelcareInvoice" */'../Pages/AddDentelcareInvoice/AddDentelcareInvoice'));
const NewLandingPage2 = lazy(() => import(/* webpackChunkName: "NewLandingPage2" */'../Pages/Login/NewLandingPage2'));
const PatientAddUpdate = lazy(() => import(/* webpackChunkName: "PatientAddUpdate" */'../Pages/PatientAddUpdate'));
const PatientDetails = lazy(() => import(/* webpackChunkName: "PatientDetails" */'../Pages/PatientDetails'));
const Patients = lazy(() => import(/* webpackChunkName: "Patients List" */'../Pages/Patients/Patients'));
const Pdfsparamsupdate = lazy(() => import(/* webpackChunkName: "Pdfsparamsupdate" */'../Pages/PdfsParamsUpdate/PdfsParamsUpdate'));
const Pdfsparamsview = lazy(() => import(/* webpackChunkName: "Pdfsparamsview" */'../Pages/PdfsParamsView/PdfsParamsView'));
const PrescriptionAddUpdate = lazy(() => import(/* webpackChunkName: "PrescriptionAddUpdate" */'../Pages/PrescriptionAddUpdate'));
const PrescriptionDetails = lazy(() => import(/* webpackChunkName: "PrescriptionDetails" */'../Pages/PrescriptionDetails'));
const PrescriptionModelAddUpdate = lazy(() => import(/* webpackChunkName: "PrescriptionModelAddUpdate" */'../Pages/PrescriptionModelAddUpdate/PrescriptionModelAddUpdate'));
const PrescriptionModelDetails = lazy(() => import(/* webpackChunkName: "PrescriptionModelDetails" */'../Pages/PrescriptionModelDetails/PrescriptionModelDetails'));
const PrescriptionModels = lazy(() => import(/* webpackChunkName: "PrescriptionModels" */'../Pages/PrescriptionModels'));
const PrescriptionPDF = lazy(() => import(/* webpackChunkName: "PrescriptionPDF" */'../Pages/PrescriptionPDF'));
const Prescriptions = lazy(() => import(/* webpackChunkName: "Prescriptions" */'../Pages/Prescriptions'));
const Prestationspage = lazy(() => import(/* webpackChunkName: "Prestationspage" */'../Pages/PrestationsPage/PrestationsPage'));
const ProductDetails = lazy(() => import(/* webpackChunkName: "ProductDetails" */'../Pages/ProductDetails/ProductDetails'));
const Products = lazy(() => import(/* webpackChunkName: "Products" */'../Pages/Products'));
const ProductsSugg = lazy(() => import(/* webpackChunkName: "ProductsSugg" */'../Pages/ProductsSugg'));
const ProductSuggDetails = lazy(() => import(/* webpackChunkName: "ProductSuggDetails" */'../Pages/ProductSuggDetails'));
const RadiologicalAssessments = lazy(() => import(/* webpackChunkName: "RadiologicalAssessments" */'../Pages/RadiologicalAssessments'));
const RadiologicalAssessmentsAddUpdate = lazy(() => import(/* webpackChunkName: "RadiologicalAssessmentsAddUpdate" */'../Pages/RadiologicalAssessmentsAddUpdate'));
const RadiologicalAssessmentsDetails = lazy(() => import(/* webpackChunkName: "RadiologicalAssessmentsDetails" */'../Pages/RadiologicalAssessmentsDetails'));
const RadiologicalAssessmentsPDF = lazy(() => import(/* webpackChunkName: "RadiologicalAssessmentsPDF" */'../Pages/RadiologicalAssessmentsPDF/RadiologicalAssessmentsPDF'));
const ReportsDetails = lazy(() => import(/* webpackChunkName: "ReportsDetails" */'../Pages/ReportsDetails/ReportsDetails'));
const DoctorGeneralPresentation = lazy(() => import(/* webpackChunkName: "DoctorGeneralPresentation" */'../Pages/DoctorGeneralPresentation/DoctorGeneralPresentation'));
const DoctorProfessionalCurriculum = lazy(() => import(/* webpackChunkName: "DoctorProfessionalCurriculum" */'../Pages/DoctorProfessionalCurriculum/DoctorProfessionalCurriculum'));
const EstablishmentGeneralPresentation = lazy(() => import(/* webpackChunkName: "EstablishmentGeneralPresentation" */'../Pages/EstablishmentGeneralPresentation/EstablishmentGeneralPresentation'));
const EstablishmentPictures = lazy(() => import(/* webpackChunkName: "EstablishmentPictures" */'../Pages/EstablishmentPictures/EstablishmentPictures'));
const EstablishmentOpeningHours = lazy(() => import(/* webpackChunkName: "EstablishmentOpeningHours" */'../Pages/EstablishmentOpeningHours/EstablishmentOpeningHours'));
const ReportsPatients = lazy(() => import(/* webpackChunkName: "ReportsPatients" */'../Pages/ReportsPatients/ReportsPatients'));
const ReportsAppointments = lazy(() => import(/* webpackChunkName: "ReportsAppointments" */'../Pages/ReportsAppointments/ReportsAppointments'));
const ReportsConsultaion = lazy(() => import(/* webpackChunkName: "ReportsConsultaion" */'../Pages/ReportsConsultaion/ReportsConsultaion'));
const PatientExportPDF = lazy(() => import(/* webpackChunkName: "PatientExportPDF" */'../Pages/PatientExportPDF/PatientExportPDF'));
const SpentList = lazy(() => import(/* webpackChunkName: "SpentList" */'../Pages/SpentList/SpentList'));
const SpentAdd = lazy(() => import(/* webpackChunkName: "SpentAdd" */'../Pages/SpentAdd/SpentAdd'));
const SpentDetails = lazy(() => import(/* webpackChunkName: "SpentDetails" */'../Pages/SpentDetails/SpentDetails'));
const ReportsList = lazy(() => import(/* webpackChunkName: "ReportsList" */'../Pages/ReportsList/ReportsList'));
const Settingprescriptiondetails = lazy(() => import(/* webpackChunkName: "Settingprescriptiondetails" */'../Pages/SettingPrescriptionDetails/SettingPrescriptionDetails'));
const Settingprescriptionupdate = lazy(() => import(/* webpackChunkName: "Appointmenttypes" */'../Pages/SettingPrescriptionUpdate/SettingPrescriptionUpdate'));
const Sittingpage = lazy(() => import(/* webpackChunkName: "Sittingpage" */'../Pages/SittingPage/SittingPage'));
const SuccessResponse = lazy(() => import(/* webpackChunkName: "SuccessResponse" */'../Pages/SuccessResponse/SuccessResponse'));
const Userlogingpage = lazy(() => import(/* webpackChunkName: "Userlogingpage" */'../Pages/UserLogingPage/UserLogingPage'));
const WaitingRoomList = lazy(() => import(/* webpackChunkName: "WaitingRoomList" */'../Pages/WaitingRoomList/WaitingRoomList'));
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */'../Pages/404'));
const DentalCareDetails = lazy(() => import(/* webpackChunkName: "DentalCareDetails" */'../Pages/DentalCareDetails'));

const SettingsDentalCareServicesTypes = lazy(() => import(/* webpackChunkName: "SettingsDentalCareServicesTypes" */'../Pages/SettingsDentalCareServicesTypes/SettingsDentalCareServicesTypes'));
const SettingsDentalCareServices = lazy(() => import(/* webpackChunkName: "SettingsDentalCareServices" */'../Pages/SettingsDentalCareServices/SettingsDentalCareServices'));
const ColleaguesList = lazy(() => import(/* webpackChunkName: "ColleaguesList" */'../Pages/ColleaguesList/ColleaguesList'));
const ColleagueAdd = lazy(() => import(/* webpackChunkName: "ColleagueAdd" */'../Pages/ColleagueAdd/ColleagueAdd'));
const ColleagueDetails = lazy(() => import(/* webpackChunkName: "ColleagueDetails" */'../Pages/ColleagueDetails/ColleagueDetails'));
const DocumentSendToColleague = lazy(() => import(/* webpackChunkName: "DocumentSendToColleague" */'../Pages/DocumentSendToColleague/DocumentSendToColleague'));
const PatientExportSelectOptions = lazy(() => import(/* webpackChunkName: "PatientExportSelectOptions" */'../Pages/PatientExportSelectOptions/PatientExportSelectOptions'));
const DentalQuoteCreate = lazy(() => import(/* webpackChunkName: "DentalQuoteCreate" */'../Pages/DentalQuoteCreate/DentalQuoteCreate'));
const Quotedetails = lazy(() => import(/* webpackChunkName: "Quotedetails" */'../Pages/Quotedetails/Quotedetails'));
const QuotesPdfViewer = lazy(() => import(/* webpackChunkName: "QuotesPdfViewer" */'../Pages/QuotesPdf'));
const DentalQuotes = lazy(() => import(/* webpackChunkName: "DentalQuotes" */'../Pages/DentalQuotes/DentalQuotes'));
const QuotationSeries = lazy(() => import(/* webpackChunkName: "QuotationSeries" */'../Pages/QuotationSeries/QuotationSeries'));
const ProstheticPrescriptions = lazy(() => import(/* webpackChunkName: "ProstheticPrescriptions" */'../Pages/ProstheticPrescriptions/ProstheticPrescriptions'));
const ProstheticPrescriptionAddUpdate = lazy(() => import(/* webpackChunkName: "ProstheticPrescriptionAddUpdate" */'../Pages/ProstheticPrescriptionAddUpdate/ProstheticPrescriptionAddUpdate'));
const ProstheticPrescriptionDetails = lazy(() => import(/* webpackChunkName: "ProstheticPrescriptionDetails" */'../Pages/ProstheticPrescriptionDetails/ProstheticPrescriptionDetails'));
const ProstheticPrescriptionPDF = lazy(() => import(/* webpackChunkName: "ProstheticPrescriptionPDF" */'../Pages/ProstheticPrescriptionPDF/ProstheticPrescriptionPDF'));
const VaccinList = lazy(() => import(/* webpackChunkName: "VaccinList" */'Pages/VaccinList/VaccinList'));
const SettingsDentaleCareFamilyPathologies = lazy(() => import(/* webpackChunkName: "SettingsDentaleCareFamilyPathologies" */'Pages/SettingsDentaleCareFamilyPathologies/SettingsDentaleCareFamilyPathologies'));
const SettingsDentaleCarePathologies = lazy(() => import(/* webpackChunkName: "SettingsDentaleCarePathologies" */'Pages/SettingsDentaleCarePathologies/SettingsDentaleCarePathologies'));
const ReportsDentalTreatments = lazy(() => import(/* webpackChunkName: "ReportsDentalTreatments" */'Pages/ReportsDentalTreatments/ReportsDentalTreatments'));

const OffersList = lazy(() => import(/* webpackChunkName: "OffersList" */'Pages/Offers/OffersList/OffersList'));
const OffreView = lazy(() => import(/* webpackChunkName: "OffreView" */'Pages/Offers/OffreView/OffreView'));

const OrdersList = lazy(() => import(/* webpackChunkName: "OrdersList" */'Pages/OrdersList/OrdersList'));
const OrderDetails = lazy(() => import(/* webpackChunkName: "OrderDetails" */'Pages/OrderDetails/OrderDetails'));
const OrderPrint = lazy(() => import(/* webpackChunkName: "OrderPrint" */'Pages/OrderPrint/OrderPrint'));

const AppNavigation = () => {

    return (
        <div>
            <BrowserRouter>
                <AnimatePresence>
                    <Toast />
                    <Suspense fallback={<LoadingPage />}>
                        <Switch>
                            <Route path={`${routesPath.authResponse.successResponse}`} exact component={SuccessResponse} />
                            <Route path={`${routesPath.authResponse.failureResponse}`} exact component={FailureResponse} />
                            <Route path={`${routesPath.authResponse.countryVerification}`} exact component={CountryVerification} />
                            {/* <Route path={`${routesPath.Login.list}`} component={NewLandingPage} exact></Route> */}
                            <Route path={`${routesPath.Login.list}`}  >{(props) => <LoginTransition><NewLandingPage2 {...props} /></LoginTransition>}</Route>

                            <PrivateRoute path={`${routesPath.Home.list}`} exact component={Home}></PrivateRoute>

                            <PrivateRoute path={`${routesPath.Patients.list}`} exact component={Patients}></PrivateRoute>

                            <PrivateRoute path={`${routesPath.Patients.details}/:id`} exact component={PatientDetails} />
                            <PrivateRoute path={`${routesPath.Patients.add}`} exact component={PatientAddUpdate} />
                            <PrivateRoute path={`${routesPath.Patients.update}/:id`} exact component={PatientAddUpdate} />
                            <PrivateRoute path={`${routesPath.Patients.export}/:id`} exact component={PatientExportPDF} />
                            <PrivateRoute path={`${routesPath.Patients.exportSelectOptions}`} exact component={PatientExportSelectOptions} />

                            <PrivateRoute path={`${routesPath.Products.list}`} exact component={Products} />
                            <PrivateRoute path={`${routesPath.Products.details}/:id`} exact component={ProductDetails} />

                            <PrivateRoute path={`${routesPath.ProductsSugg.list}`} exact component={ProductsSugg} />
                            <PrivateRoute path={`${routesPath.ProductsSugg.details}/:id`} exact component={ProductSuggDetails} />


                            <PrivateRoute path={`${routesPath.Ordinances.list}`} exact component={Prescriptions} />
                            <PrivateRoute path={`${routesPath.Ordinances.add}`} exact component={PrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.Ordinances.details}/:id`} exact component={PrescriptionDetails} />
                            <PrivateRoute path={`${routesPath.Ordinances.update}/:id`} exact component={PrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.Ordinances.duplicate}/:id`} exact component={PrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.Ordinances.prescriptionPdf}/:id`} exact component={PrescriptionPDF} />

                            <PrivateRoute path={`${routesPath.RadiologicalAssessments.list}`} exact component={RadiologicalAssessments} aclActions={[aclActions?.Prescriptions?.list]} />
                            <PrivateRoute path={`${routesPath.RadiologicalAssessments.add}`} exact component={RadiologicalAssessmentsAddUpdate} aclActions={[aclActions?.Prescriptions?.list]} />
                            <PrivateRoute path={`${routesPath.RadiologicalAssessments.details}/:id`} exact component={RadiologicalAssessmentsDetails} aclActions={[aclActions?.Prescriptions?.details]} />
                            <PrivateRoute path={`${routesPath.RadiologicalAssessments.update}/:id`} exact component={RadiologicalAssessmentsAddUpdate} aclActions={[aclActions?.Prescriptions?.update]} />
                            <PrivateRoute path={`${routesPath.RadiologicalAssessments.duplicate}/:id`} exact component={RadiologicalAssessmentsAddUpdate} aclActions={[aclActions?.Prescriptions?.update]} />
                            <PrivateRoute path={`${routesPath.RadiologicalAssessments.prescriptionPdf}/:id`} exact component={RadiologicalAssessmentsPDF} aclActions={[aclActions?.Prescriptions?.details]} />

                            <PrivateRoute path={`${routesPath.biologicalPrescriptions.list}`} exact component={BiologicalPrescriptions} />
                            <PrivateRoute path={`${routesPath.biologicalPrescriptions.add}`} exact component={BiologicalPrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.biologicalPrescriptions.update}/:id`} exact component={BiologicalPrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.biologicalPrescriptions.duplicate}/:id`} exact component={BiologicalPrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.biologicalPrescriptions.details}/:id`} exact component={BiologicalPrescriptionDetails} />
                            <PrivateRoute path={`${routesPath.biologicalPrescriptions.prescriptionPdf}/:id`} exact component={BiologicalPrescriptionPDF} />

                            <PrivateRoute path={`${routesPath.prostheticPrescriptions.list}`} exact component={ProstheticPrescriptions} />
                            <PrivateRoute path={`${routesPath.prostheticPrescriptions.add}`} exact component={ProstheticPrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.prostheticPrescriptions.update}/:id`} exact component={ProstheticPrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.prostheticPrescriptions.duplicate}/:id`} exact component={ProstheticPrescriptionAddUpdate} />
                            <PrivateRoute path={`${routesPath.prostheticPrescriptions.details}/:id`} exact component={ProstheticPrescriptionDetails} />
                            <PrivateRoute path={`${routesPath.prostheticPrescriptions.prescriptionPdf}/:id`} exact component={ProstheticPrescriptionPDF} />


                            <PrivateRoute path={`${routesPath.Calendar.list}`} exact component={Calendar} />
                            <PrivateRoute path={`${routesPath.Calendar.details}/:id`} exact component={AppointmentDetails} />


                            <PrivateRoute path={`${routesPath.prescriptionModels.list}`} exact component={PrescriptionModels} />
                            <PrivateRoute path={`${routesPath.prescriptionModels.details}/:id`} exact component={PrescriptionModelDetails} />
                            <PrivateRoute path={`${routesPath.prescriptionModels.add}`} exact component={PrescriptionModelAddUpdate} />
                            <PrivateRoute path={`${routesPath.prescriptionModels.update}/:id`} exact component={PrescriptionModelAddUpdate} />

                            <PrivateRoute path={`${routesPath.Consultations.list}`} exact component={Consultations} />
                            <PrivateRoute path={`${routesPath.Consultations.details}/:id/patient/:patientId`} exact component={DentalCareDetails} />
                            <PrivateRoute path={`${routesPath.Consultations.add}`} exact component={ConsultationAddUpdate} />
                            <PrivateRoute path={`${routesPath.Consultations.update}/:id`} exact component={ConsultationAddUpdate} />
                            {/* <PrivateRoute path={`${routesPath.DentalCare.details}/:id/patient/:patientId`} exact component={DentalCareDetails} /> */}

                            <PrivateRoute path={`${routesPath.Common.error_404}`} exact component={PageNotFound} />

                            <PrivateRoute path={`${routesPath.Invoices.list}`} exact component={Invoiceslist} />
                            <PrivateRoute path={`${routesPath.Invoices.details}/:id`} exact component={Invoicesdetails} />
                            <PrivateRoute path={`${routesPath.Invoices.details}/:id/print`} exact component={Invoicespdf} />
                            <PrivateRoute path={`${routesPath.Invoices.add}`} exact component={Invoicecreate} />
                            <PrivateRoute path={`${routesPath.Invoices.addDentelcareInvoice}`} exact component={AddDentelcareInvoice} />

                            <PrivateRoute path={`${routesPath.Quote.details}/:id`} exact component={Quotedetails} />
                            <PrivateRoute path={`${routesPath.Quote.add}`} exact component={DentalQuoteCreate} />
                            <PrivateRoute path={`${routesPath.Quote.pdf}/:id`} exact component={QuotesPdfViewer} />
                            <PrivateRoute path={`${routesPath.Quote.list}`} exact component={DentalQuotes} />


                            <PrivateRoute path={`${routesPath.Invoices.addPayments}/:id`} exact component={Approvefactures} />
                            <PrivateRoute path={`${routesPath.Invoices.addPaymentsDetails}/:id`} exact component={PaymentDetailsInvoice} />
                            <PrivateRoute path={`${routesPath.Invoices.addPayments}`} exact component={Approvefactures} />
                            <PrivateRoute path={`${routesPath.Invoices.exportSales}`} exact component={FacturesExportsales} />


                            <PrivateRoute path={`${routesPath.Settings.list}`} exact component={Sittingpage} />
                            <PrivateRoute path={`${routesPath.Settings.userLoging}`} exact component={Userlogingpage} />
                            <PrivateRoute path={`${routesPath.Settings.prescriptionDetails}`} exact component={Settingprescriptiondetails} />
                            <PrivateRoute path={`${routesPath.Settings.prescriptionUpdate}`} exact component={Settingprescriptionupdate} />

                            <PrivateRoute path={`${routesPath.Settings.pdfsParamsView}`} exact component={Pdfsparamsupdate} />
                            <PrivateRoute path={`${routesPath.Settings.pdfsParamsUpdate}`} exact component={Pdfsparamsview} />

                            <PrivateRoute path={`${routesPath.Settings.invoicepPdfsParamsView}`} exact component={Invoiceppdfsparamsview} />
                            <PrivateRoute path={`${routesPath.Settings.invoicepPdfsParamsUpdate}`} exact component={Invoiceppdfsparamsupdate} />

                            <PrivateRoute path={`${routesPath.Settings.vaccin}`} exact component={VaccinList} />
                            {/* <PrivateRoute path={`${routesPath.Settings.vaccinAddOrUpdate}`} exact component={VaccinAddOrUpdate} /> */}

                            <PrivateRoute path={`${routesPath.Settings.appointmenttypes}`} exact component={Appointmenttypes} />
                            <PrivateRoute path={`${routesPath.Settings.appointmentAverageTime}`} exact component={AppointmentAverageTime} />

                            <PrivateRoute path={`${routesPath.Settings.users}`} exact component={Aclusers} />
                            <PrivateRoute path={`${routesPath.Settings.fieldsList}`} exact component={Fieldslist} />
                            <PrivateRoute path={`${routesPath.Settings.consultationtabs}`} exact component={Consultationtabs} />

                            <PrivateRoute path={`${routesPath.Settings.aclRoles}`} exact component={Aclroles} />
                            <PrivateRoute path={`${routesPath.Settings.aclMethods}`} exact component={Aclmethods} />

                            <PrivateRoute path={`${routesPath.Settings.invoiceTypes}`} exact component={Invoicetypes} />
                            <PrivateRoute path={`${routesPath.Settings.prestations}`} exact component={Prestationspage} />
                            <PrivateRoute path={`${routesPath.Settings.invoiceSeries}`} exact component={Invoiceseries} />
                            <PrivateRoute path={`${routesPath.Settings.quotationSeries}`} exact component={QuotationSeries} />

                            <PrivateRoute path={`${routesPath.Settings.documents}`} exact component={DocumentsModelsList} />
                            <PrivateRoute path={`${routesPath.Settings.documentAddOrUpdate}`} exact component={DocumentAddOrUpdate} />

                            <PrivateRoute path={`${routesPath.WaitingRoom.list}`} exact component={WaitingRoomList} />

                            <PrivateRoute path={`${routesPath.Reports.list}`} exact component={ReportsList} />
                            <PrivateRoute path={`${routesPath.Reports.details}`} exact component={ReportsDetails} />
                            <PrivateRoute path={`${routesPath.Reports.patients}`} exact component={ReportsPatients} />
                            <PrivateRoute path={`${routesPath.Reports.appointments}`} exact component={ReportsAppointments} />
                            <PrivateRoute path={`${routesPath.Reports.consultaion}`} exact component={ReportsConsultaion} />
                            <PrivateRoute path={`${routesPath.Reports.dentalTreatments}`} exact component={ReportsDentalTreatments} />

                            <PrivateRoute path={`${routesPath.Documents.list}`} exact component={DocumentsList} />
                            <PrivateRoute path={`${routesPath.Documents.add}`} exact component={GenerateDocument} />
                            <PrivateRoute path={`${routesPath.Documents.send}`} exact component={DocumentSendToColleague} />

                            <PrivateRoute path={`${routesPath.Spent.list}`} exact component={SpentList} />
                            <PrivateRoute path={`${routesPath.Spent.add}`} exact component={SpentAdd} />
                            <PrivateRoute path={`${routesPath.Spent.details}/:id`} exact component={SpentDetails} />

                            <PrivateRoute path={`${routesPath.Settings.dentaleCareSevicesTypes}`} exact component={SettingsDentalCareServicesTypes} />
                            <PrivateRoute path={`${routesPath.Settings.dentaleCareSevices}`} exact component={SettingsDentalCareServices} />

                            <PrivateRoute path={`${routesPath.Settings.dentaleCareFamilyPathologies}`} exact component={SettingsDentaleCareFamilyPathologies} />
                            <PrivateRoute path={`${routesPath.Settings.dentaleCarePathologies}`} exact component={SettingsDentaleCarePathologies} />

                            <PrivateRoute path={`${routesPath.Colleagues.list}`} exact component={ColleaguesList} />
                            <PrivateRoute path={`${routesPath.Colleagues.add}`} exact component={ColleagueAdd} />
                            <PrivateRoute path={`${routesPath.Colleagues.update}/:id`} exact component={ColleagueAdd} />
                            <PrivateRoute path={`${routesPath.Colleagues.details}/:id`} exact component={ColleagueDetails} />


                            <PrivateRoute path={`${routesPath.Offers.list}`} exact component={OffersList} />
                            <PrivateRoute path={`${routesPath.Offers.details}`} exact component={OffreView} />

                            <PrivateRoute path={`${routesPath.Orders.list}`} exact component={OrdersList} />
                            <PrivateRoute path={`${routesPath.Orders.details}/:id`} exact component={OrderDetails} />
                            <PrivateRoute path={`${routesPath.Orders.details}/:id/print`} exact component={OrderPrint} />

                            <PrivateRoute path={`${routesPath.Settings.doctorGeneralPresentation}`} exact component={DoctorGeneralPresentation} />
                            <PrivateRoute path={`${routesPath.Settings.doctorProfessionalCurriculum}`} exact component={DoctorProfessionalCurriculum} />

                            <PrivateRoute path={`${routesPath.Settings.establishmentGeneralPresentation}`} exact component={EstablishmentGeneralPresentation} />
                            <PrivateRoute path={`${routesPath.Settings.establishmentPictures}`} exact component={EstablishmentPictures} />
                            <PrivateRoute path={`${routesPath.Settings.establishmentOpeningHours}`} exact component={EstablishmentOpeningHours} />
                            <Route path={`*`} component={PageNotFound} />
                        </Switch>
                    </Suspense>
                </AnimatePresence>
            </BrowserRouter>
        </div>
    );
}

export default AppNavigation;