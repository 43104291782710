import classNames from 'classnames';
import { motion } from "framer-motion";
import React from 'react';

const pageTransition = {
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
}

const Transition = (props) => {
    const {
        className,
        cssModule,
        children,
        ...attributes
    } = props;

    const classes = classNames(
        className,
        "sobrus-med-container",
    );

    return (
        <motion.div className={classes} initial="out" animate="in" exit="out" variants={pageTransition} {...attributes}>
            {
                children
            }
        </motion.div>
    );
}

const LoginTransition = (props) => {
    const {
        className,
        cssModule,
        children,
        ...attributes
    } = props;

    const classes = classNames(
        className,
    );

    return (
        <motion.div className={classes} initial="out" animate="in" exit="out" variants={pageTransition} {...attributes}>
            {
                children
            }
        </motion.div>
    );
}

export { LoginTransition };

export default Transition;

