
const initialState = {
    sickTeeth: [],
    selectedItem: [],
    deletedPathologies: [],
    pathologyFaces: [],
};

function dentalPathologiesReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "ADD_PATHOLOGY_FACE":
            nextState = {
                ...state,
                pathologyFaces: [...state.pathologyFaces, action.value],
            };
            return nextState || state;
        case "SET_ALL_PATHOLOGIES_FACES":
            nextState = {
                ...state,
                pathologyFaces: [...action.value],
            };
            return nextState || state;

        case "ADD_SICK_TEETH":
            nextState = {
                ...state,
                sickTeeth: [...state.sickTeeth, ...action.value],
            };
            return nextState || state;
        case "SET_ALL_SICK_TEETH":
            nextState = {
                ...state,
                sickTeeth: [...action.value],
            };
            return nextState || state;
        case "ADD_DELETED_PATHOLOGIES_ITEM":
            nextState = {
                ...state,
                deletedPathologies: [...state.deletedPathologies, action.value],
            };
            return nextState || state;
        case "SET_ALL_DELETED_PATHOLOGIES":
            nextState = {
                ...state,
                deletedPathologies: action.value,
            };
            return nextState || state;
        case "SET_SELECTED_TEETH":
            nextState = {
                ...state,
                selectedItem: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const addSickTeeth = (value) => ({ type: "ADD_SICK_TEETH", value })
const setAllSickTeeth = (value) => ({ type: "SET_ALL_SICK_TEETH", value })
const setSelectedItem = (value) => ({ type: "SET_SELECTED_TEETH", value })

const addDeletedPathologies = (value) => ({ type: "ADD_DELETED_PATHOLOGIES_ITEM", value })
const setAllDeletedPathologies = (value) => ({ type: "SET_ALL_DELETED_PATHOLOGIES", value })

const addPathologyFace = (value) => ({ type: "ADD_PATHOLOGY_FACE", value })
const setAllPathologiesFaces = (value) => ({ type: "SET_ALL_PATHOLOGIES_FACES", value })


// export variables
export { addSickTeeth, setSelectedItem, setAllSickTeeth, addDeletedPathologies, setAllDeletedPathologies, addPathologyFace, setAllPathologiesFaces };
export default dentalPathologiesReducer;