
const initialState = {
    establishmentParamsForUsage: null,
    loadingEstablishmentParamsForUsage: true,
};

function establishmentParamsForUsageReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_ESTABLISHMENT_PARAMS_FOR_USAGE":
            nextState = {
                ...state,
                establishmentParamsForUsage: action.value,
            };
            return nextState || state;
        case "LOADING_ESTABLISHMENT_PARAMS_FOR_USAGE":
            nextState = {
                ...state,
                loadingEstablishmentParamsForUsage: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
// define Redux actions for this reducer
const setEstablishmentParamsForUsage = (value) => ({ type: "SET_ESTABLISHMENT_PARAMS_FOR_USAGE", value })
const setLoadingEstablishmentParamsForUsage = (value) => ({ type: "LOADING_ESTABLISHMENT_PARAMS_FOR_USAGE", value })

// export variables
export { setEstablishmentParamsForUsage, setLoadingEstablishmentParamsForUsage };
export default establishmentParamsForUsageReducer;